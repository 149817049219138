import { React } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Message } from 'primereact/message';

const Home = ({ message }) => {
  const { user } = useAuth()

  if (message) {
    return <Message severity="error" text={message} className="flex mt-2" ></Message>
  }

  /*if(!user){
      return null
    }  
  */

  return (
    <div>
      {user && <h1> Добрый день, {user.firstname}! </h1>}
    </div>
  )
}

export default Home;
