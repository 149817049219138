import axios from 'axios'
import { React, useEffect, useState } from 'react'
import { MultiSelect } from 'primereact/multiselect'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button'
import { ProgressSpinner } from 'primereact/progressspinner'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber'
import { Tooltip } from 'primereact/tooltip'
import { Message } from 'primereact/message'
import { FileUpload } from 'primereact/fileupload'
import moment from 'moment'

const BorderSubs = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [dataFetched, setDataFetched] = useState(false)

  const [gridData, setGridData] = useState([])

  /*   const [filters2, setFilters2] = useState({
      'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
      'Site Name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      'representative': { value: null, matchMode: FilterMatchMode.IN },
      'status': { value: null, matchMode: FilterMatchMode.EQUALS },
      'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
    }) */

  useEffect(() => {
    const makeApiReq = async () => {
      try {
        const res = await axios.get('/api/ulocells')
        setGridData(res.data)
        setDataFetched(true)
        setIsLoading(false)
      } catch (error) {
        console.log(error);
      }
    }
    makeApiReq().catch(console.log)
  }, [])



  const exportExcel = () => {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(gridData);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      saveAsExcelFile(excelBuffer, 'ulocells_config');
    });
  }

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(module => {
      if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });
        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  }

  const importCSV = (e) => {
    const file = e.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const csv = e.target.result;
      const data = csv.split('\n');

      /*       // Prepare DataTable
            const cols = data[0].replace(/['"]+/g, '').split(',');
            data.shift();
      
            const importedData = data.map(d => {
              d = d.split(',');
              const processedData = cols.reduce((obj, c, i) => {
                c = c === 'Status' ? 'inventoryStatus' : (c === 'Reviews' ? 'rating' : c.toLowerCase());
                obj[c] = d[i].replace(/['"]+/g, '');
                (c === 'price' || c === 'rating') && (obj[c] = parseFloat(obj[c]));
                return obj;
              }, {});
      
              processedData['id'] = createId();
              return processedData;
            });
      
            const _products = [...products, ...importedData];
      
            setProducts(_products);
             */
    };

    reader.readAsText(file, 'UTF-8');
  }






  const uploadOptions = { label: 'Импортировать в БД', icon: 'pi pi-upload', className: 'p-button-info p-button-sm' };

  const header = (
    <>
      <div className="flex align-items-center export-buttons">
        <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-button-sm mr-2" data-pr-tooltip="Экспортировать в Excel" />
        <FileUpload mode="basic" name="demo[]" auto url="https://primefaces.org/primereact/showcase/upload.php" accept=".csv" chooseOptions={uploadOptions} className="mr-2 inline-block" onUpload={importCSV} />
      </div>

    </>
  );

  const footer = `Всего записей: ${gridData.length}`


  return (
    <>
      {
        isLoading ? (
          <div className="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        ) : (

          <>
            {gridData.length > 0 ? (
              <div className="datatable-scroll-demo">
                <div className="card mt-4" style={{ height: 'calc(100vh - 50px)' }}>
                  <Tooltip target=".export-buttons>button" position="bottom" />



                  <DataTable value={gridData} showGridlines scrollable scrollHeight="flex" footer={footer} stripedRows resizableColumns columnResizeMode="fit"
                    size="small" header={header} dataKey="_id" responsiveLayout="scroll" emptyMessage="Записей не найдено."
                  >
                    <Column field="Site Name" header="Site Name" filter sortable />



                    <Column field="Local Cell ID" header="Local Cell ID" />
                    <Column field="Cell Name" header="Cell Name" filter sortable />
                    <Column field="Frequency band" header="Frequency band" />
                    <Column field="Downlink EARFCN" header="Downlink EARFCN" />
                    <Column field="Uplink bandwidth" header="Uplink bandwidth" />
                    <Column field="Downlink bandwidth" header="Downlink bandwidth" />
                    <Column field="TAC" header="TAC" />
                    <Column field="Cell ID" header="Cell ID" />
                    <Column field="Physical cell ID" header="Physical cell ID" sortable />
                    <Column field="Cell FDD TDD indication" header="Cell FDD TDD indication" />
                    <Column field="Root sequence index" header="Root sequence index" sortable />
                    <Column field="eNB ID" header="eNB ID" sortable />
                    <Column field="ECI (DEC)" header="ECI (DEC)" sortable />
                    <Column field="Longitude (decimal)" header="Longitude (decimal)" sortable />
                    <Column field="Latitude (decimal)" header="Latitude (decimal)" sortable />
                    <Column field="Height (m)" header="Height (m)" sortable />
                    <Column field="Azimuth (°)" header="Azimuth (°)" sortable />
                    <Column field="Mechanical Tilt" header="Mechanical Tilt" />
                    <Column field="Electrical Tilt" header="Electrical Tilt" />
                    <Column field="Total downtilt" header="Total downtilt" />
                    <Column field="Vendor" header="Vendor" filter />
                    <Column field="Billing Cell Name" header="Billing Cell Name" filter sortable />
                    <Column field="Region" header="Region" filter sortable />
                    <Column field="Date" header="Date" />
                    <Column field="Sharing" header="Sharing" />
                  </DataTable>
                </div>
              </div>
            ) :
              dataFetched &&
              <div className="grid">
                <div className="col-12 mt-5">
                  <Message severity="error" text="Записей не найдено" className='w-full' />
                </div>
              </div>
            }
          </>
        )
      }
    </>
  )
}

export default BorderSubs