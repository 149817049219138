import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { Panel } from 'primereact/panel';
import { useAuth } from '../../contexts/AuthContext';

function Login({ message }) {
  const [formValues, setFormValues] = useState('');
  //const [loading, setLoading] = useState(false)
  const { login, user, error } = useAuth()

  const navigate = useNavigate();
  const location = useLocation();

  const redirectPath = location.state?.path || '/home'

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //setLoading(true)
    login(formValues)
    //setLoading(false)
  };

  useEffect(() => {
    if(user !== null) navigate(redirectPath)
  }, [user])

  //{message && (<Message severity="error" text={message} className="flex mt-2" ></Message>)} 
  //{location.state?.message && (<Message severity="error" text={location.state.message} className="flex mt-2" ></Message>)}

  return (
    <>
      {error && (<Message severity="error" text={error} className="flex mt-2" ></Message>)}
      <form onSubmit={handleSubmit}>
        <div className="grid mt-8 p-3">
          <div className="sm:col-12 lg:col-4"></div>
          <div className="sm:col-12 lg:col-4">
            <Panel header="Вход в систему" className="w-full">
              <div className="col-12">
                <label htmlFor="email-input">Email</label>
                <InputText
                  id="email-input"
                  name="email"
                  label="Email"
                  type="text"
                  size="small"
                  value={formValues?.name}
                  onChange={handleInputChange}
                  className="w-full"
                />
              </div>

              <div className="col-12">
                <label htmlFor="password-input">Пароль</label>
                <InputText
                  id="password-input"
                  name="password"
                  label="Password"
                  type="password"
                  size="small"
                  value={formValues?.name}
                  onChange={handleInputChange}
                  className="w-full"
                />
              </div>

              <div className="col-12">
                <Button
                  type="submit"
                  className="flex p-button-info w-full mt-4"
                  label="Вход"
                ></Button>
              </div>
            </Panel>
          </div>
          <div className="sm:col-12 lg:col-4"></div>
        </div>
      </form>
    </>
  );
}

export default Login;