import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import columnDefs from './columnDefs';

let dateColumn = null;
const curDate = new Date()

const PcrfLogs = () => {
  const dbFilters = [
    { dbName: 'upcc_8', columns: ['MSISDN', 'SERVICENAME', 'QUOTANAME'], dateColumn: 'DATE' },
    { dbName: 'upcc_18', columns: ['SERVICENAME'], dateColumn: 'DATE' },
    { dbName: 'upcc_20', columns: ['SUB_ID'], dateColumn: 'OPERATION_TIME' },
  ];

  const [searchColumns, setSearchColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dbs, setDbs] = useState([]);
  const [formValues, setFormValues] = useState({ startDate: curDate, endDate: curDate });
  const [gridData, setGridData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState()
  const navigate = useNavigate()
  const { user } = useAuth()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await axios.get('/api/pcrf-logs', { headers: { Authorization: `Bearer ${user.accessToken}` } })
        setDbs(data.data);
      } catch (error) {
        if (error.response.status === 403) {
          navigate('/error', { state: { message: "Нет прав для просмотра данной страницы", severity: 'error' } });
        }
        if (error.response.status === 401) {
          navigate('/login', { state: { message: "Необходима авторизация", severity: 'error' } });
        }
      }
    };

    fetchData().catch(console.error);
  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'database') {
      setGridData([]);
      const dbMatched = dbFilters.filter((db) => db.dbName === value);
      setSearchColumns(dbMatched[0].columns);
      const columnsDefinitionMatched = columnDefs.filter(
        (obj) => obj.db === dbMatched[0].dbName
      );
      setColumns(columnsDefinitionMatched[0].columns);
      dateColumn = dbMatched[0].dateColumn;
      if (formValues) {
        const newFormValues = formValues;
        delete newFormValues.searchColumn;
        delete newFormValues.searchValue;
        setFormValues(newFormValues);
      }
    }
    setFormValues({ ...formValues, [name]: value, dateColumn });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setGridData([]);

    const response = await axios.post('/api/pcrf-logs', formValues, { headers: { Authorization: `Bearer ${user.accessToken}` } });
    setGridData(response.data);
    setLoading(false);
  };

  const exportExcel = () => {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(gridData);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      saveAsExcelFile(excelBuffer, 'pcrf-logs');
    });
  }

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(module => {
      if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });
        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  }

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success mr-2" data-pr-tooltip="Экспортировать в Excel" />
    </div>
  );

  const footer = `Всего записей: ${gridData.length}`


  return (

    <>
      <form onSubmit={handleSubmit}>
        <div className="grid mt-3">
          <div className="col-12 md:col-6 lg:col-2">
            <Dropdown name="database" value={formValues?.database ?? ''} options={dbs} onChange={handleChange} placeholder="Выберите тип данных" className='w-full' />
          </div>
          <div className="col-12 md:col-6 lg:col-2">
            <Dropdown name="searchColumn" value={formValues?.searchColumn ?? ''} options={searchColumns} onChange={handleChange} placeholder="Искать в столбце" className='w-full' />
          </div>

          <div className="col-12 md:col-6 lg:col-2">
            <span className="p-float-label">
              <InputText id="searchValue" name="searchValue" value={formValues?.searchValue ?? ''} onChange={handleChange} className='w-full' />
              <label htmlFor="searchValue">Ключевое слово</label>
            </span>
          </div>

          <div className="col-12 md:col-6 lg:col-2">
            <Calendar id="startDate" dateFormat="yy-mm-dd" name="startDate" value={formValues.startDate} selectOtherMonths="true" onChange={handleChange} showIcon="true" icon="pi pi-calendar" tooltip="Начало" tooltipOptions={{ className: 'teal-tooltip', position: 'top' }} className='w-full'></Calendar>
          </div>

          <div className="col-12 md:col-6 lg:col-2">
            <Calendar id="endDate" dateFormat="yy-mm-dd" name="endDate" value={formValues.endDate} selectOtherMonths="true" onChange={handleChange} showIcon="true" icon="pi pi-calendar" tooltip="Конец" tooltipOptions={{ className: 'teal-tooltip', position: 'top' }} className='w-full'></Calendar>
          </div>

          <div className="col-12 md:col-6 lg:col-2">
            <Button label="Искать" className="p-button-raised p-button-secondary w-full" onClick={handleSubmit} />
          </div>
        </div>
      </form>

      {loading ? (
        <div className="flex align-items-center justify-content-center">
          <ProgressSpinner />
        </div>
      ) : (
        <>
          {gridData.length > 0 ? (
            <div className="datatable-scroll-demo">
              <div className="card mt-4" style={{ height: 'calc(100vh - 200px)' }}>
                <Tooltip target=".export-buttons>button" position="bottom" />
                <DataTable value={gridData} showGridlines scrollable scrollHeight="flex" stripedRows resizableColumns columnResizeMode="fit" size="small" header={header} footer={footer} dataKey="id" responsiveLayout="scroll" emptyMessage="No logs found.">
                  {columns.map((col, index) => <Column key={index} field={col.field} header={col.header} sortable={col.sortable} />)}
                </DataTable>
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default PcrfLogs;
