import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext'
import { Panel } from 'primereact/panel';
import { Message } from 'primereact/message'
import { React, useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useAuth } from '../../contexts/AuthContext'
import useAxios from '../../hooks/useAxios'

const PcrfProteiOrderedPackagesTest = () => {
  const [msisdn, setMsisdn] = useState(992)
  const [buttonClicked, setButtonClicked] = useState(false)
  const buttonReference = useRef(null);
  const { user } = useAuth()
  const { response, error, setError, loading, fetchData } = useAxios();
  const [gridData, setGridData] = useState([])


  useEffect(() => {
    if (response !== null) {
      setGridData(response.sim_package_list)
    }
  }, [response]);

  const handleChange = (e) => {
    setMsisdn(e.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    fetchData({ method: 'get', url: '/api/pcrf-protei-ordered-packages-test', params: { 'msisdn': msisdn } })
    setButtonClicked(true)
  }


  useEffect(() => {
    buttonReference.current?.focus();
  }, [msisdn])

  if (!user) {
    return null
  }


  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="grid mt-2">
          <div className="sm:col-6 p-2">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <i className="pi pi-phone"></i>
              </span>
              <InputText id="msisdn" name="msisdn" value={msisdn} keyfilter="int" onChange={handleChange} placeholder="MSISDN (992XXXXXXX)" className={gridData?.code === 'msisdn_invalid' ? "p-invalid block w-full" : "w-full p-inputtext-sm"} />
            </div>
          </div>

          <div className="sm:col-6 p-2">
            <Button label="Искать" className="p-button-sm p-button-info w-full" ref={buttonReference} onClick={handleSubmit} disabled={msisdn.length !== 12} />
          </div>
        </div>

      </form>

      {gridData?.type === 'ERROR' && <Message severity="error" text={gridData.text} className="w-full mt-3" />}
      {gridData === '' && <Message severity="info" text={`У абонента ${msisdn} нет пакетов`} className="w-full mt-3" />}

      {gridData?.SimGprsPackage?.length > 0 && (
        <div className="grid mt-4">
          <div className="col-12">
            <Panel header={`Пакеты, подключенные номеру ${msisdn}`}>
              <TabView>
                {gridData.SimGprsPackage.map(item => {
                  return (
                    <TabPanel key={item.id} header={item.packageName} className="w-full">
                      <div><strong>Package Name: </strong> {item.packageName}</div>
                      <div><strong>Package ID: </strong> {item.packageId}</div>
                      <div><strong>Created: </strong>{item.created}</div>
                      <div><strong>State: </strong>{item.state}</div>
                      <div><strong>Admin State: </strong>{item.adminState}</div>
                      <div><strong>Activated: </strong>{item.activated}</div>
                      <div><strong>Subscription Start: </strong>{item.subscriptionStart}</div>
                      <div><strong>Subscription End: </strong>{item.subscriptionEnd}</div>
                      <div><strong>Expired: </strong>{item.expired}</div>
                      <div><strong>Usage Start: </strong>{item.usageStart}</div>
                      <div><strong>Usage End: </strong>{item.usageEnd}</div>
                      <div><strong>Next Bill Date: </strong>{item.nextBillDate}</div>
                      <div><strong>Main Package Volume: </strong>{item.mainPackageVolume}</div>
                      <div><strong>Penalty Package Volume: </strong>{item.penaltyPackageVolume}</div>
                      {item.penaltyDeltaVolume && <div><strong>Penalty Delta Volume:</strong> {item.penaltyDeltaVolume.delta[0]}</div>}
                      {item.penaltyDeltaLeft && <div><strong>Penalty Delta Left:</strong> {item.penaltyDeltaLeft.delta[0]}</div>}
                      <div><strong>Current Traffic: </strong>{item.currentTraffic}</div>
                      <div><strong>Total Traffic: </strong>{item.totalTraffic}</div>
                      <div><strong>Main Traffic Left: </strong>{item.mainTrafficLeft}</div>
                      <div><strong>Penalty Traffic Left: </strong>{item.penaltyTrafficLeft}</div>
                      <div><strong>Last Sms Threshold: </strong>{item.lastSmsThreshold}</div>
                      <div><strong>Renew Number: </strong>{item.renewNumber}</div>
                      <div><strong>Package Type: </strong>{item.packageType}</div>
                    </TabPanel>
                  )
                })}
              </TabView>
            </Panel>
          </div>
        </div>

      )}

      {gridData?.SimGprsPackage?.id && (
        <div className="grid mt-4">
          <div className="col-12">
            <Panel header={`Пакеты, подключенные номеру ${msisdn}`}>
              <TabView>
                <TabPanel key={gridData?.SimGprsPackage?.id} header={gridData?.SimGprsPackage?.packageName} className="w-full">
                  <div><strong>Package Name</strong>: {gridData.SimGprsPackage.packageName}</div>
                  <div><strong>Package ID</strong>: {gridData.SimGprsPackage.packageId}</div>
                  <div><strong>Created</strong>: {gridData.SimGprsPackage.created}</div>
                  <div><strong>State</strong>: {gridData.SimGprsPackage.state}</div>
                  <div><strong>Admin State</strong>: {gridData.SimGprsPackage.adminState}</div>
                  <div><strong>Activated</strong>: {gridData.SimGprsPackage.activated}</div>
                  <div><strong>Subscription Start</strong>: {gridData.SimGprsPackage.subscriptionStart}</div>
                  <div><strong>Subscription End</strong>: {gridData.SimGprsPackage.subscriptionEnd}</div>
                  <div><strong>Expired</strong>: {gridData.SimGprsPackage.expired}</div>
                  <div><strong>Usage Start</strong>: {gridData.SimGprsPackage.usageStart}</div>
                  <div><strong>Usage End</strong>: {gridData.SimGprsPackage.usageEnd}</div>
                  <div><strong>Next Bill Date</strong>: {gridData.SimGprsPackage.nextBillDate}</div>
                  <div><strong>Main Package Volume</strong>: {gridData.SimGprsPackage.mainPackageVolume}</div>
                  <div><strong>Penalty Package Volume</strong>: {gridData.SimGprsPackage.penaltyPackageVolume}</div>
                  {gridData.SimGprsPackage.penaltyDeltaVolume && <div><strong>Penalty Delta Volume:</strong> {gridData.SimGprsPackage.penaltyDeltaVolume.delta[0]}</div>}
                  {gridData.SimGprsPackage.penaltyDeltaLeft && <div><strong>Penalty Delta Left:</strong> {gridData.SimGprsPackage.penaltyDeltaLeft.delta[0]}</div>}
                  <div><strong>Current Traffic</strong>: {gridData.SimGprsPackage.currentTraffic}</div>
                  <div><strong>Total Traffic</strong>: {gridData.SimGprsPackage.totalTraffic}</div>
                  <div><strong>Main Traffic Left</strong>: {gridData.SimGprsPackage.mainTrafficLeft}</div>
                  <div><strong>Penalty Traffic Left</strong>: {gridData.SimGprsPackage.penaltyTrafficLeft}</div>
                  <div><strong>Last Sms Threshold</strong>: {gridData.SimGprsPackage.lastSmsThreshold}</div>
                  <div><strong>Renew Number</strong>: {gridData.SimGprsPackage.renewNumber}</div>
                  <div><strong>Package Type</strong>: {gridData.SimGprsPackage.packageType}</div>
                </TabPanel>
              </TabView>
            </Panel>
          </div>
        </div>
      )}
    </>
  )
};

export default PcrfProteiOrderedPackagesTest;