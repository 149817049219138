import React from 'react';
import { Message } from 'primereact/message';

export const PageNotFound = () => {
  return (
    <div className="App">
        <Message severity="error" text="Страница не найдена" className="w-full mt-3" />
    </div>
  );
};
