import axios from "axios";
import { useState } from 'react';
import { useAuth } from "../contexts/AuthContext";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const useAxios = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useAuth()
  const navigate = useNavigate()
  let accessToken = user?.accessToken

  const fetchData = async (params) => {
    setLoading(true)
    let decodedAT = null

    if (user) {
      decodedAT = jwtDecode(user?.accessToken)
    }

    try {
      if (!decodedAT || decodedAT.exp < new Date().getTime() / 1000) {
        const { data } = await axios.get('/api/refresh', { withCredentials: true })
        accessToken = data.accessToken
      }

      const result = await axios.request({ ...params, headers: { Authorization: `Bearer ${accessToken}` } });
      setUser(prevState => ({ ...prevState, accessToken: accessToken }))
      setResponse(result.data);
    } catch (error) {
      //console.log(error.response?.data.message);
      setError(error.response?.data.message);
      navigate("/login")
    } finally {
      setLoading(false);
    }
  };
  return { response, error, loading, fetchData };
}

export default useAxios