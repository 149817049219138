import { useEffect, useState, useRef } from "react";
import { useAuth } from '../../contexts/AuthContext'
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputNumber } from "primereact/inputnumber";
import { Message } from "primereact/message";
import useAxios from '../../hooks/useAxios'

function SubData() {
  const [data, setData] = useState(null)
  const [msisdn, setMsisdn] = useState(992)
  const buttonReference = useRef(null);

  const { user } = useAuth();
  const { response, error, setError, loading, fetchData } = useAxios();

  useEffect(() => {
    if (response !== null) {
      setData(response.data)
    }
  }, [response]);


  const handleSubmit = async (e) => {
    e.preventDefault()
    fetchData({ method: 'get', url: '/api/iplook-subdata', params: { msisdn: msisdn } })
    //setButtonClicked(true)
  }

  useEffect(() => {
    buttonReference.current?.focus();
  }, [msisdn])

  if (!user) return null

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='p-fluid grid formgrid mt-5'>
          <div className="col-12 md:col-6">
            <InputNumber value={msisdn} pattern='^[992]{3}[0-9]{9}$' onChange={(e) => setMsisdn(e.value)} placeholder="992XXXXXXXXX" useGrouping={false} />
          </div>
          <div className="col-12 md:col-6">
            <Button label="Поиск" className="p-button-warning w-full" disabled={msisdn.toString().length !== 12} />
          </div>
        </div>
      </form>

      {response?.status === "error" && <Message severity="error" text={response.message} className="w-full mt-6" />}

      {data && (
        <div className='mt-3'>
          <TabView>
            {Object.keys(data).map((key, value) =>
              <TabPanel header={key} key={key}>
                <pre>
                  {JSON.stringify(data[key], null, 2)}
                </pre>
              </TabPanel>
            )}
          </TabView>
        </div>
      )}
    </>
  )
}

export default SubData