import { useState, useEffect } from 'react'
import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Tooltip } from 'primereact/tooltip'
import { useAuth } from '../../contexts/AuthContext'
import useAxios from '../../hooks/useAxios'
import { Message } from 'primereact/message'

const PcrfProteiLogs = () => {
  const { user } = useAuth()
  const [formValues, setFormValues] = useState({ msisdn: '', startDate: '', endDate: '' });
  const [gridData, setGridData] = useState([]);
  const { response, error, setError, loading, fetchData } = useAxios();
  const [buttonClicked, setButtonClicked] = useState(false)


  const handleSubmit = (e) => {
    e.preventDefault()
    setButtonClicked(true)
    setGridData([]);
    //setError(null)
    fetchData({ method: 'post', url: 'api/pcrf-protei-logs', data: formValues })
  }

  useEffect(() => {
    if (response !== null) {
      setGridData(response);
    }
  }, [response]);


  const handleChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value });
  }

  if (!user) {
    return null
  }

  const footer = `Всего записей: ${gridData?.length}`

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='p-fluid grid formgrid mt-5'>
          <div className="col-12 md:col-6 lg:col-3">
            <span className="p-float-label">
              <InputText id='msisdn' name='msisdn' value={formValues.msisdn} onChange={handleChange} className="w-full" />
              <label htmlFor="msisdn">MSISDN</label>
            </span>
          </div>

          <div className="col-12 md:col-6 lg:col-3">
            <span className="p-float-label">
              <Calendar id="startDate" name="startDate" value={formValues.startDate} onChange={handleChange} dateFormat="yy-mm-dd" className='w-full' showIcon></Calendar>
              <label htmlFor="startDate">Начало периода</label>
            </span>
          </div>

          <div className="col-12 md:col-6 lg:col-3">
            <span className="p-float-label">
              <Calendar id="endDate" name="endDate" value={formValues.endDate} onChange={handleChange} dateFormat="yy-mm-dd" className="w-full" showIcon />
              <label htmlFor="endDate">Конец периода</label>
            </span>
          </div>

          <div className="col-12 md:col-6 lg:col-3">
            <Button label="Поиск" className="p-button-warning w-full" disabled={(formValues.msisdn === '' || formValues.startDate === '' || formValues.endDate === '')} />
          </div>
        </div>
      </form>

      {error && (<Message severity="error" text={"error"} className="flex mt-2" ></Message>)}



      {loading ? (
        <div className="flex align-items-center justify-content-center">
          <ProgressSpinner />
        </div>
      ) : (
        <>
          {gridData.length > 0 ? (
            <div className="datatable-scroll-demo">
              <div className="card mt-4" style={{ height: 'calc(100vh - 180px)' }}>
                <Tooltip target=".export-buttons>button" position="bottom" />
                <DataTable value={gridData} showGridlines scrollable scrollHeight="flex" stripedRows resizableColumns columnResizeMode="fit" size="small" header={`Данные по номеру ${gridData[0].Msisdn}`} footer={footer} dataKey="DateTime" emptyMessage="No logs found.">
                  <Column field="DateTime" header="Дата" sortable></Column>
                  <Column field="IP" header="IP"></Column>
                  <Column field="URL" header="API Запрос" filter></Column>
                  <Column field="Command" header="Команда" sortable></Column>
                  <Column field="ResultCode" header="Код ответа"></Column>
                  <Column field="PassTime" header="Время выполнения (мс)" sortable></Column>
                </DataTable>
              </div>
            </div>
          )
            : (
              <>
                {
                  buttonClicked && (
                    <div className="flex align-items-center justify-content-center mt-4">
                      <Message severity='info' text='Записей за указанный период не найдено'></Message>
                    </div>)
                }
              </>
            )
          }
        </>
      )}
    </>

  )
}

export default PcrfProteiLogs