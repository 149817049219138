const columnDefs = [
  {
    db: 'upcc_8',
    columns: [
      { field: 'id', header: '#', minWidth: 5, sortable: false, exportable: false },
      {
        field: 'DATE',
        header: 'Date',
        sortable: true,
        exportable: true
      },
      {
        field: 'MSISDN',
        header: 'MSISDN',
        sortable: true,
        exportable: true
      },
      {
        field: 'SERVICENAME',
        header: 'Service Name',
        sortable: true,
        exportable: true
      },
      {
        field: 'SUBSCRIBEDATETIME',
        header: 'Subscribe Date',
        sortable: true
      },
      {
        field: 'VALIDFROMDATETIME',
        header: 'Valid From',
        sortable: true
      },
      {
        field: 'EXPIREDDATETIME',
        header: 'Expired',
        sortable: true
      },
      {
        field: 'QUOTANAME',
        header: 'Quota Name',
        sortable: true
      },
      {
        field: 'INITIALVALUE',
        header: 'Initial Value',
        sortable: true
      },
      {
        field: 'BALANCE',
        header: 'Balance',
        sortable: true
      },
      {
        field: 'CONSUMPTION',
        header: 'Consumption',
        sortable: true
      },
    ],
  },

  {
    db: 'upcc_18',
    columns: [
      { field: 'id', header: '#', sortable: false },
      {
        field: 'DATE',
        header: 'DATE',
        sortable: true
      },
      {
        field: 'SERVICENAME',
        header: 'Service Name',
        sortable: true
      },
      {
        field: 'SUBSCRIBEDTOTALTIMES',
        header: 'Subscribed Total Times',
        sortable: true
      },
    ],
  },

  {
    db: 'upcc_20',
    columns: [
      { field: 'id', header: '#', maxWidth: 5, sortable: false },
      {
        field: 'SERIAL_NO',
        header: 'Serial #',
        sortable: true
      },
      {
        field: 'OPERATION_TIME',
        header: 'Operation Time',
        sortable: true
      },
      {
        field: 'MML_COMMAND',
        header: 'MML Command',
        sortable: true
      },
      {
        field: 'CMDRESULT',
        header: 'CMD Result',
        sortable: true
      },
      {
        field: 'SUB_ID',
        header: 'MSISDN',
        sortable: true
      },
    ],
  },
];

export default columnDefs;