import { createContext, useContext, useState } from "react";
import axios from 'axios';

const AuthContext = createContext(null)

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [error, setError] = useState(null)
  //const [isLoading, setIsLoading] = useState(true);
  const [allowedPaths, setAllowedPaths] = useState([])


  const login = async (payload) => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    try {
      const { data } = await axios.post('/api/login', payload, config);
      if (data?.error) {
        console.log('err');
        setError(data.error);
      }
      setUser(data)
    } catch (e) {
      //setIsLoading(false);
      console.log(e.message);
      setError('Ошибка аутентификации!')
    } finally {
      //setIsLoading(false);
    }
  }

  const logout = async () => {
    try {
      const { data } = await axios.post('/api/logout', { user })
      console.log(data);
    } catch (error) {

    }
    setUser(null)
  }


  const value = { user, setUser, login, logout, allowedPaths, setAllowedPaths, error, setError }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider