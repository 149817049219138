import { React, useEffect, useState } from 'react'
import { MultiSelect } from 'primereact/multiselect'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button'
import { ProgressSpinner } from 'primereact/progressspinner'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber'
import { Tooltip } from 'primereact/tooltip'
import { Message } from 'primereact/message'
import { useAuth } from '../../contexts/AuthContext'
import useAxios from '../../hooks/useAxios'
import moment from 'moment'


const BorderSubs = () => {
  const [regions, setRegions] = useState([])
  const [selectedRegions, setSelectedRegions] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [appearences, setAppearences] = useState(2)
  const [gridData, setGridData] = useState([])
  const [buttonClicked, setButtonClicked] = useState(false)

  const { user } = useAuth();
  const { response, error, setError, loading, fetchData } = useAxios();

  useEffect(() => {
    fetchData({ method: 'get', url: '/api/border-subs' })
  }, [])


  useEffect(() => {
    if (response !== null) {
      if (response.regions) {
        setRegions(response.regions)
      } else {
        setGridData(response)
      }
    }
  }, [response]);


  const handleSubmit = async(e) => {
    e.preventDefault()
    const data = { regions: selectedRegions, startDate: moment(startDate).format("YYYY-MM-DD"), endDate: moment(endDate).format("YYYY-MM-DD"), appearences: appearences }
    fetchData({ method: 'post', url: '/api/border-subs', data })
    setButtonClicked(true)
  }

  const exportExcel = () => {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(gridData);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      saveAsExcelFile(excelBuffer, 'border-subs-suspicious');
    });
  }

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(module => {
      if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });
        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  }

  const header = (
    <>
      <div className="flex align-items-center export-buttons">
        <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-button-sm mr-2" data-pr-tooltip="Экспортировать в Excel" />
      </div>
    </>
  );

  const footer = `Всего записей: ${gridData.length}`

  if (!user) {
    return null
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='p-fluid grid formgrid mt-5'>
          <div className="col-12 md:col-6 lg:col-3">
            <span className="p-float-label">
              <MultiSelect id="region" value={selectedRegions} options={regions} onChange={(e) => setSelectedRegions(e.value)} maxSelectedLabels={3} className="w-full" scrollHeight='400px' />
              <label htmlFor="region">Регион</label>
            </span>
          </div>

          <div className="col-12 md:col-6 lg:col-2">
            <span className="p-float-label">
              <Calendar id="startDate" dateFormat="yy-mm-dd" value={startDate} onChange={(e) => setStartDate(e.value)} name="startDate" showIcon="true" icon="pi pi-calendar" className='w-full'></Calendar>
              <label htmlFor="startDate">Начало периода</label>
            </span>
          </div>

          <div className="col-12 md:col-6 lg:col-2">
            <span className="p-float-label">
              <Calendar id="endDate" value={endDate} onChange={(e) => setEndDate(e.value)} dateFormat="yy-mm-dd" className="w-full" showIcon />
              <label htmlFor="endDate">Конец периода</label>
            </span>
          </div>

          <div className="col-12 md:col-6 lg:col-1">
            <span className="p-float-label">
              <InputNumber inputId="minmax-buttons" value={appearences} onValueChange={(e) => setAppearences(e.value)} mode="decimal" showButtons min={1} max={10} className="w-full" />
              <label htmlFor="minmax-buttons">Совпадений</label>
            </span>
          </div>

          <div className="col-12 md:col-6 lg:col-4">
            <Button label="Поиск" className="p-button-warning w-full" disabled={(selectedRegions.length < 1 || startDate === null || endDate === null || startDate > endDate) ? true : false} />
          </div>
        </div>
      </form>

      {
        loading ? (
          <div className="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        ) : (
          <>
            {gridData.length > 0 ? (
              <div className="datatable-scroll-demo">
                <div className="card mt-4" style={{ height: 'calc(100vh - 200px)' }}>
                  <Tooltip target=".export-buttons>button" position="bottom" />
                  <DataTable value={gridData} showGridlines scrollable scrollHeight="flex" footer={footer} stripedRows resizableColumns columnResizeMode="fit" size="small" header={header} dataKey="id" emptyMessage="Записей не найдено.">
                    <Column field="msisdn" header="Номер" sortable />
                    <Column field="date" header="Дата" sortable />
                    <Column field="cell_name" header="Сектор" sortable />
                    <Column field="region" header="Регион" sortable />
                    <Column field="cell" header="ID сектора" sortable />
                    <Column field="registrations" header="Регистраций в секторе" sortable />
                  </DataTable>
                </div>
              </div>
            ) :
              buttonClicked &&
              <div className="grid">
                <div className="col-12 mt-5">
                  <Message severity="error" text="Записей не найдено" className='w-full' />
                </div>
              </div>
            }
          </>
        )
      }
    </>
  )
}

export default BorderSubs