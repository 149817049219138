import axios from 'axios';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import { Divider } from 'primereact/divider';
import { Panel } from 'primereact/panel';
import { React, useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import useAxios from '../../hooks/useAxios'
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';

const PcrfProteiPackages = ({ message }) => {
  const [packageNames, setPackageNames] = useState([{ label: 'Все пакеты', code: 'Все пакеты' }]);
  const [selectedPkgCode, setSelectedPackageCode] = useState('Все пакеты');
  const [packages, setPackages] = useState([]);
  const [selectedPkg, setSelectedPackage] = useState([])
  const { response, error, setError, loading, fetchData } = useAxios();
  const { user } = useAuth()

  useEffect(() => {
    const callApi = async () => {
      fetchData({ method: 'get', url: '/api/pcrf-protei-packages' })
    }
    callApi().catch(console.log);
  }, []);

  useEffect(() => {
    if (response !== null) {
      const pkgs = response.list.GprsPackage

      let pkgNames = []
      pkgs.map(pkg => {
        const packageObj = { label: pkg.name, code: pkg.packageNumber }
        pkgNames.push(packageObj)
      })
      pkgNames.unshift({ label: 'Все пакеты', code: 'Все пакеты' })
      setPackageNames(pkgNames)
      setPackages(pkgs);
    }
  }, [response]);


  useEffect(() => {
    if (selectedPkgCode !== 'Все пакеты') {
      const matchedPackage = packages.filter(pkg =>
        pkg.packageNumber === Number(selectedPkgCode)
      )
      setSelectedPackage(matchedPackage)
    }
  }, [selectedPkgCode])


  const handleClick = (e) => {
    const packageCode = Number(e.currentTarget.getAttribute('value'));
    setSelectedPackageCode(packageCode)
  }

  const handleChange = (e) => {
    setSelectedPackageCode(e.target.value)
  };

  if (!user) return null

  if (loading) return (<div className="grid mt-2"> <ProgressSpinner /></div>)

  return (
    <>
      <div className="grid mt-2">
        <div className="col-12">
          <Dropdown name="package-names" optionLabel="label" optionValue="code" value={selectedPkgCode} options={packageNames} onChange={handleChange} placeholder="Все пакеты" className="w-full mb-4" />
        </div>
      </div>

      {selectedPkgCode === 'Все пакеты' && (
        <div className="grid">
          {packages.length > 0 && packages.map((pkg) => {
            return (
              <div key={`div${pkg.packageNumber}`} className="сol-12 p-2 md:col-6 lg:col-3">
                <Panel key={pkg.packageNumber} name='panel' value={pkg.packageNumber} header={pkg.name} onDoubleClick={handleClick} >
                  Код пакета: {pkg.packageNumber} <br />
                  Правило покупки пакета: {pkg.orderingPolicy} <br />
                  Административное состояние: {pkg.blocked} <br />
                  Приоритет: {pkg.priority} <br />
                  {/* 
                  Corp package: {pkg['primary-package']?.['corp-package'] ? 'true' : 'false'} <br />
                  Network ID: {pkg['primary-package']?.['network-id']} <br />
                  APN: {pkg['primary-package']?.['apn']} <br />
                  Cellset ID: {pkg['primary-package']?.['cellset-id']} <br />
                  Priority: {pkg.priority} <br />
 */}
                </Panel>
              </div>
            );
          })}
        </div>
      )}


      {selectedPkgCode !== 'Все пакеты' && selectedPkg.length > 0 && (
        <div className="grid w-full">
          <div className="lg:col-2 md:col-3 sm:col-12"></div>
          <div className="lg:col-8 md:col-6 sm:col-12">
            <Panel header={selectedPkg[0].name} className="w-full m-2">
              <TabView className="w-full">
                <TabPanel header="Параметры пакета">
                  <Divider>Основное </Divider>
                  <div>Код пакета: {selectedPkg[0].packageNumber} </div>
                  <div>Детали: {selectedPkg[0].serviceInfo} </div>
                  <div>Приоритет: {selectedPkg[0].priority} </div>
                  <div>Правило покупки пакета: {selectedPkg[0].orderingPolicy} </div>

                  <Divider>Объем </Divider>
                  <div>Объем: {selectedPkg[0].trafficVolume} </div>
                  <div>Правило округления трафика: {selectedPkg[0].blockSize} </div>

                  <Divider>Жизненный цикл </Divider>
                  <div>Активируется при первом использовании: {selectedPkg[0].activateOnFirstUsage} </div>
                  <div>Использовать календарное время: {selectedPkg[0].alignedDates} </div>
                  <div>Тип Подписки: {selectedPkg[0].subscriptionPeriodType} </div>
                  <div>Подписка: {selectedPkg[0].subscriptionPeriod} </div>
                  <div>Тип использования: {selectedPkg[0].usagePeriodType} </div>
                  <div>Использование: {selectedPkg[0].usagePeriod} </div>
                  <div>Время сброса счетчика: {selectedPkg[0].validTill} </div>
                  {/* <div>Full first period cost: {selectedPkg[0]['primary-package']?.['lifecycle']?.['full-first-period-cost'] ? 'true' : 'false'} </div> */}
                  <div>Правило срока действия: {selectedPkg[0].expiryDays} </div>
                  <div>Автоматическое продление: {selectedPkg[0].autorenew} </div>
                  <div>Ограничение продления: {selectedPkg[0].renewLimit} </div>
                </TabPanel>

                {/* selectedPkg[0]['nested-packages']?.['nested-package'] && (
                  <TabPanel header="Nested Packages">
                    {selectedPkg[0]['nested-packages']?.['nested-package'].map(nestedPkg =>
                      <div key={nestedPkg['package-name']}>
                        <div>Package name: {nestedPkg['package-name']} </div>
                        <div>Package extid: {nestedPkg['package-extid']} </div>
                        <div>Package priority: {nestedPkg['package-priority']} </div>
                        <div>Volume value: {nestedPkg['main-volume']?.['volume-value']} </div>
                      </div>
                    )}
                  </TabPanel>
                ) */}
              </TabView>
            </Panel>
          </div>
          <div className="lg:col-2 md:col-3 sm:col-12"></div>
        </div>
      )
      }

    </>
  );
};

export default PcrfProteiPackages;